import React, { useEffect, useState } from "react"
import { graphql, PageProps } from "gatsby"
import getSearchParam from "@ecom/ui/utils/getSearchParam"

import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../components/Layouts/mainPage"
import { NewHeader } from "../components/Headers/NewHeader"
import { Banner } from "../components/Banners/Pkw"
import { Banner as RoundedDynamicBanner } from "../components/Banners/DynamicBanners/RoundedDynamicBanner"
import { HowInstallmentWorks } from "../components/HowInstallmentWorks"
import { FormPKW } from "../components/FormPKW"
import { NewCalculator } from "../components/NewCalculator/NewCalculator"
import { MoreBenefitsRedisign } from "../components/MorBenefitsRedisgn"
import { FourAdvantagesPoints } from "../components/FourAdvantagesPoints"
import { OtherCards } from "../components/OtherCards"
import { BottomCta, RefinSeoBgImg } from "../components/BottomCta"
import { Partners } from "../components/PartnersNew"
import { Reviews } from "../components/Reviews"
import { Questions } from "../components/Questions"
import { NewFooter } from "../components/NewFooter"

import { getPageData } from "../helpers/getPageData"

import { BannerText, getDynamicBannerText } from "../helpers/getDynamicBannerText"
import { getDynamicBannerUrl } from "../helpers/getDynamicBannerUrl"

import { PageData } from "../interfaces/pageProps"
import { QUESTIONS_FOR_INDEX_PKW } from "../components/Questions/helpers"
import { isKameleoonVariation } from "../utils/kameleoonCheckVariation"

const titleForm = (
  <>
    Оформите карту сейчас – <br /> получите подарок!
  </>
)

const bannerDescription = `
  — До 17% на остаток по карте
  <br />
  — Кэшбэк до 10%
  <br />
  — Обслуживание 0 ₽ 
`

const subTitlePartners =
  "Рассрочка 24 месяца в первый месяц использования Халвы – у всех партнеров. Со второго месяца срок рассрочки на новые покупки устанавливает партнер:"

const GIFT_TITLE = (
  <>
    Оформите Халву и получите
    <br />
    <b> месяц&nbsp;подписки&nbsp;в&nbsp;подарок</b>
  </>
)

// Время для таймер(30мин)
const countDownTime = 1800000

const longForm = isKameleoonVariation("21632667_halva_form_long", 874539)

export default function Page({ data }: PageProps<PageData>) {
  const isMobile = useMediaQuery("(max-width:380px)")
  const bannerTitle = isMobile
    ? `<span>Карта «Халва»</span>
  <br/>
  24 месяца<br/>рассрочки
  `
    : `
  <span>Карта «Халва»</span>
  <br/>
  24 месяца&nbsp;рассрочки
`

  const { ligal, noIndex } = getPageData(data)

  const [dynamicBannerTextObj, setDynamicBannerTextObj] = useState<BannerText>()
  const [dynamicBannerImagesObj, setDynamicBannerImagesObj] = useState<any>()

  const bannerTextUrl = getSearchParam("h")
  const bannerImageUrl = getSearchParam("bn")

  useEffect(() => {
    if (bannerImageUrl) {
      const bannerImagesData = getDynamicBannerUrl(data.allDynamicBanner, bannerImageUrl)
      if (bannerImagesData !== null) {
        setDynamicBannerImagesObj(bannerImagesData)
      }
    }
  }, [data.allDynamicBanner, bannerImageUrl])

  useEffect(() => {
    if (bannerTextUrl) {
      getDynamicBannerText(bannerTextUrl).then((result) => {
        setDynamicBannerTextObj(result)
      })
    }
  }, [bannerTextUrl])

  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  return (
    <Layout
      noHeader
      noFooter
      noIndex={noIndex}
      seoData={{
        title: "Карта рассрочки халва с бесплатной доставкой",
        description:
          "Покупки товаров и услуг в рассрочку без переплат и первоначального взноса! Бесплатная доставка карты рассрочки Халва Совкомбанка. Быстрое одобрение. Заполните заявку на Халву онлайн!",
      }}
    >
      <NewHeader showHint={false} hasCTA additionalEventInDataLayer countDownTime={countDownTime} />
      {dynamicBannerImagesObj && bannerImageUrl ? (
        <RoundedDynamicBanner
          large={dynamicBannerImagesObj.dymanicBannerDesk}
          mob={dynamicBannerImagesObj.dymanicBannerMob}
          title={dynamicBannerTextObj ? dynamicBannerTextObj.title : bannerTitle}
          description={dynamicBannerTextObj ? dynamicBannerTextObj.description : bannerDescription}
          orderNum="1"
        />
      ) : (
        <Banner orderNum="1" title={bannerTitle} description={bannerDescription} />
      )}
      <FourAdvantagesPoints
        withTitle
        indent
        variant="pkwRedesign"
        title="Преимущества с Халвой:"
        orderNum="2"
      />
      <FormPKW
        dataLayerName={longForm ? "longPersonalForm" : "shortPersonalForm"}
        title={titleForm}
        longTitle
        additionalEventInDataLayer
        longForm={longForm}
        countDownTime={countDownTime}
        withTimer
        formBtnText="Оформить карту"
        orderNum="3"
        onlyPKWPage
        isGreenApprove
        navigateVerifySMS={!longForm}
        productName="Халва из МП ХКБ"
      />
      <HowInstallmentWorks
        headTitle="Как получить рассрочку на&nbsp;24&nbsp;месяца"
        variant="pkwRedesign"
        orderNum="4"
      />
      <NewCalculator additionalEventInDataLayer orderNum="5" />
      <OtherCards orderNum="6" />
      <Partners
        title="Более 250&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="7"
      />
      <MoreBenefitsRedisign
        additionalEventInDataLayer
        orderNum="8"
        hasSubtitle
        variant="pkwItemsNewHint"
        bottomBlock={false}
      />
      <BottomCta variant="pkwRedesign" title={GIFT_TITLE} BgImg={RefinSeoBgImg} orderNum="9" />
      <Reviews additionalEventInDataLayer orderNum="10" />
      <Questions questionList={QUESTIONS_FOR_INDEX_PKW} additionalEventInDataLayer orderNum="11" />
      <NewFooter ligal={ligal} orderNum="12" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/rassrochka-s-halvoi/") {
        url
        ligal {
          text
        }
        notIndex
      }
    }
  }
`
